@import url("https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body{
  background-color:#fff;
}
.btn-agendamento{
  background-color:#1D63AE;
  color:#fff;
  transition-duration: 0.6s;
}
.btn-agendamento-2{
  background-color:rgb(99, 121, 145);
}
a.btn-agendamento-2{
  color:#fff;
}
.subtitulo{
  text-align: center;
}
.all{
  background-color:#FFF !important;
}
a.btn-agendamento-2:hover{
  color:#fff;
}
.btn-agendamento-2:hover{
  background-color:rgb(65, 80, 95);
}
.btn-agendamento:hover{
    background-color: rgb(14, 40, 70);
    color:#fff;
}
.form-inline{
  flex-flow: nowrap !important;
}
