@import url(https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont,'Roboto', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body{
  background-color:#fff;
}
.btn-agendamento{
  background-color:#1D63AE;
  color:#fff;
  transition-duration: 0.6s;
}
.btn-agendamento-2{
  background-color:rgb(99, 121, 145);
}
a.btn-agendamento-2{
  color:#fff;
}
.subtitulo{
  text-align: center;
}
.all{
  background-color:#FFF !important;
}
a.btn-agendamento-2:hover{
  color:#fff;
}
.btn-agendamento-2:hover{
  background-color:rgb(65, 80, 95);
}
.btn-agendamento:hover{
    background-color: rgb(14, 40, 70);
    color:#fff;
}
.form-inline{
  flex-flow: nowrap !important;
}

.title{
  text-align: center;
}
.espacamentoTop{
  margin-top:20px;
}
.txtBlack{
  color:#000 !important;
}
.tag-email{
  background-color:#f2f2f2;
  padding:10px;
  color:#000 !important; 
}
.espacamento{
  margin-bottom: 10px;
  margin-top: 10px;
}
.colorBlack{
  color:#f00 !important;
}
button span{
  align-self: center;
  font-size: 18px !important;
}
.linkVideo{
  text-decoration: underline !important;
  color:#000 !important; 
}
.large{
  width: 100%;
}
.font-black{
  color:#000 !important;
}
.wrap{
  wrap:wrap;
}
.alinhar-texto-centro{
  text-align: center;
}
.hideElement{
  display:none;
}
.logoBox{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width:300px;
  margin: 0 auto;
}
.logoBox p{
  text-align: center;
  color:#000 !important;
  
}
.bloco-logo{
  margin-top:30px;
}
.espacamentoTop{
  margin-top:10px;
}

.navbarNome {
    cursor: pointer;
}

.navbar {
    box-shadow: 4px 28px 24px -34px rgba(0, 0, 0, 0.75);
}

* {
    list-style: none;
}

a .nav-link {
    color: #000 !important;
}

a {
    cursor: pointer;
}

nav {
    min-height: 80px;
}

nav {
    background-color: #3498db !important;
}

.bg-agendamento {
    background-color: #1D63AE !important;
    color: #fff !important;
}

.navbar-agendamento>*, .navbar-agendamento>li {
    color: #fff !important;
}

.navbar-light {
    color: #fff;
}

.itemNome {
    color: rgb(241, 235, 235);
}

.itemNome:hover {
    color: #fff;
}

.navbar-nav {
    color: #fff !important;
}

.navbar-toggler-icon {
    color: #fff !important;
}

@media only screen and (min-width: 900px) {
    .menu-button :hover {
        border-bottom: 1px solid #fff !important;
    }
}

.statusNome {
    font-size: 20px;
}

.border-agendamento {
    border-color: #1D63AE;
}

.card-header {
    background-color: #1D63AE;
    color: #fff;
    font-weight: bold;
}

.text-agendamento {
    color: #1D63AE;
}
.all {
  background-color: #fff;
}

.all img {
  padding-top: 5px;
  padding-left: 5px;
  padding: 20px;
}
.imagem-logo{

}
.imgDiv {
  display: flex;
  justify-content: center;
}
.alinharTexto{
  text-align: center;
}

div.container p {
  color: #fff;
}
.medium{
  width: 70%;
}
.login {
  max-width: 600px;
  background-color: #f2f2f2;
  box-shadow: -1px 5px 39px -8px rgba(0, 0, 0, 0.75);
  margin: 0 auto;
  margin-top: 20px;
}

.login .header {
  height: 190px;
  background-color: #1D63AE;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

}
.title-login{
  text-align: center;
  color:#fff;
}

.login .body {
  padding: 20px;
}

.espacamento {
  margin: 20px;
}

.Fontblack {
  color: #000 !important;
}

input {
  width: 90% !important;
}

@media only screen and (max-width: 900px) {
  input {
    width: 85% !important;
  }
}

@media only screen and (max-width:488px) {
  .espacamentoAltura {
    margin-top: 10px;
  }
}
