.all {
  background-color: #fff;
}

.all img {
  padding-top: 5px;
  padding-left: 5px;
  padding: 20px;
}
.imagem-logo{

}
.imgDiv {
  display: flex;
  justify-content: center;
}
.alinharTexto{
  text-align: center;
}

div.container p {
  color: #fff;
}
.medium{
  width: 70%;
}
.login {
  max-width: 600px;
  background-color: #f2f2f2;
  -webkit-box-shadow: -1px 5px 39px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 5px 39px -8px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 5px 39px -8px rgba(0, 0, 0, 0.75);
  margin: 0 auto;
  margin-top: 20px;
}

.login .header {
  height: 190px;
  background-color: #1D63AE;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

}
.title-login{
  text-align: center;
  color:#fff;
}

.login .body {
  padding: 20px;
}

.espacamento {
  margin: 20px;
}

.Fontblack {
  color: #000 !important;
}

input {
  width: 90% !important;
}

@media only screen and (max-width: 900px) {
  input {
    width: 85% !important;
  }
}

@media only screen and (max-width:488px) {
  .espacamentoAltura {
    margin-top: 10px;
  }
}