.title{
  text-align: center;
}
.espacamentoTop{
  margin-top:20px;
}
.txtBlack{
  color:#000 !important;
}
.tag-email{
  background-color:#f2f2f2;
  padding:10px;
  color:#000 !important; 
}
.espacamento{
  margin-bottom: 10px;
  margin-top: 10px;
}
.colorBlack{
  color:#f00 !important;
}
button span{
  align-self: center;
  font-size: 18px !important;
}
.linkVideo{
  text-decoration: underline !important;
  color:#000 !important; 
}
.large{
  width: 100%;
}
.font-black{
  color:#000 !important;
}
.wrap{
  wrap:wrap;
}
.alinhar-texto-centro{
  text-align: center;
}
.hideElement{
  display:none;
}