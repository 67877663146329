.navbarNome {
    cursor: pointer;
}

.navbar {
    -webkit-box-shadow: 4px 28px 24px -34px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 4px 28px 24px -34px rgba(0, 0, 0, 0.75);
    box-shadow: 4px 28px 24px -34px rgba(0, 0, 0, 0.75);
}

* {
    list-style: none;
}

a .nav-link {
    color: #000 !important;
}

a {
    cursor: pointer;
}

nav {
    min-height: 80px;
}

nav {
    background-color: #3498db !important;
}

.bg-agendamento {
    background-color: #1D63AE !important;
    color: #fff !important;
}

.navbar-agendamento>*, .navbar-agendamento>li {
    color: #fff !important;
}

.navbar-light {
    color: #fff;
}

.itemNome {
    color: rgb(241, 235, 235);
}

.itemNome:hover {
    color: #fff;
}

.navbar-nav {
    color: #fff !important;
}

.navbar-toggler-icon {
    color: #fff !important;
}

@media only screen and (min-width: 900px) {
    .menu-button :hover {
        border-bottom: 1px solid #fff !important;
    }
}

.statusNome {
    font-size: 20px;
}

.border-agendamento {
    border-color: #1D63AE;
}

.card-header {
    background-color: #1D63AE;
    color: #fff;
    font-weight: bold;
}

.text-agendamento {
    color: #1D63AE;
}