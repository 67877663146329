.logoBox{
  display:flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width:300px;
  margin: 0 auto;
}
.logoBox p{
  text-align: center;
  color:#000 !important;
  
}
.bloco-logo{
  margin-top:30px;
}
.espacamentoTop{
  margin-top:10px;
}
